<template>
  <!-- 1 -->
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <vue-dropzone
        ref="docUpload1"
        id="docUpload1"
        :useCustomSlot="true"
        :options="dropzoneOptions"
        v-on:vdropzone-sending="fnSendingDoc1"
        @vdropzone-queue-complete="fnAfterSendingDoc1"
        class="quoteDocUpload mb800"
      >
        <div class="dropzone-custom-content text-center p300">
          <div class="subtitle">
            <i class="fas fa-cloud-upload-alt fs-1000 text-info"></i
            ><span class="subtitle fs-900 pl-2">Upload document/s</span>
          </div>
          <b-spinner small v-if="loading" class="mr-2"></b-spinner>
          <span v-if="loading">Uploading...</span>
        </div>
      </vue-dropzone>

      <div class="tag-input">
        <div class="tag-input__tag">
          <div v-if="doc_urls">
            <span
              ><a class="fs-800" :href="`${doc_urls}`" target="_blank"
                >{{ documentType }} - {{ 1 }}</a
              >
            </span>
            <a
              href="javascript:void(0)"
              @click="fnDocumentRemove(documentTypeId, 1)"
              class="pl-2 text-danger fs-800"
              ><i class="far fa-times-circle"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import store from "../../store/modules/base.module";
import * as authService from "../../services/auth.service";
import vueDropzone from "vue2-dropzone";
import * as messageService from "../../services/message.service";
import * as policeCheckService from "../../services/policeCheck.service";

export default {
  name: "TypeFrom1",
  props: ["policeCheckId", "doc_urls", "documentTypeId", "documentType"],
  components: {
    vueDropzone,
  },

  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getPoliceCheckSingle"]),
    dropzoneOptions() {
      return {
        url: `${store.state.apiURL}/police-check/document-upload`,
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
        thumbnailWidth: 75,
        maxFilesize: 200,
        uploadMultiple: true,
        maxFiles: 10,
        paralleUploads: 10,
        previewsContainer: false,
        acceptedFiles: ".pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .png",
      };
    },
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle"]),
    // Dropzone docuemt upload
    fnSendingDoc1(file, xhr, formData) {
      this.loading = true;

      formData.append("pId", this.policeCheckId);
      formData.append("documentTypeId", this.documentTypeId);
    },
    // Dropzone docuemt upload after
    fnAfterSendingDoc1(file) {
      this.fetchPoliceCheckSingle(this.policeCheckId);
      this.loading = false;
    },
    fnDocumentRemove: async function (documentTypeId, index) {
      let obj = {
        documentTypeId: documentTypeId,
        index: index,
      };

      try {
        await policeCheckService.removeDoc(this.policeCheckId, obj);
        this.fetchPoliceCheckSingle(this.policeCheckId);

        this.error = {};

        messageService.fnToastSuccess("Document removed successfully!");
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Document removed",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Document removed",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Photo remove",
              "Something wrong, please try again!"
            );
            break;
        }
      }
    },
  },
};
</script>

<template>
  <!-- 9 -->
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <select
        class="form-control"
        @change="updateValue($event)"
        :value="value.state"
        ref="state"
      >
        <option
          :value="state.value"
          v-for="(state, i) in optionsState"
          :key="i"
        >
          {{ state.text }}
        </option>
      </select>
      <label class="floatingLabel vueBootSelect">State</label>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          autocomplete="off_doc1_given_name"
          placeholder=" "
          :value="value.given_name"
          @input="updateValue($event)"
          ref="given_name"
        />
        <label class="floatingLabel">Given name</label>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          autocomplete="off_doc1_middle_name"
          placeholder=" "
          :value="value.middle_name"
          @input="updateValue($event)"
          ref="middle_name"
        />
        <label class="floatingLabel">Middle name</label>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          autocomplete="off_doc1_family_name"
          placeholder=" "
          :value="value.family_name"
          @input="updateValue($event)"
          ref="family_name"
        />
        <label class="floatingLabel">Family name</label>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          autocomplete="off_doc1_reference_no"
          placeholder=" "
          :value="value.reference_no"
          @input="updateValue($event)"
          ref="reference_no"
        />
        <label class="floatingLabel">Registration #</label>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <div class="form-group">
        <input
          class="form-control"
          type="date"
          autocomplete="off_doc1_date_certificate_issued"
          placeholder=" "
          :value="value.date_certificate_issued"
          @input="updateValue($event)"
          ref="date_certificate_issued"
        />
        <label class="floatingLabel">Date Certificate Issued</label>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <vue-dropzone
        ref="docUpload1"
        id="docUpload1"
        :useCustomSlot="true"
        :options="dropzoneOptions"
        v-on:vdropzone-sending="fnSendingDoc1"
        @vdropzone-queue-complete="fnAfterSendingDoc1"
        class="quoteDocUpload mb800"
      >
        <div class="dropzone-custom-content text-center p300">
          <div class="subtitle">
            <i class="fas fa-cloud-upload-alt fs-1000 text-info"></i
            ><span class="subtitle fs-900 pl-2">Upload document/s</span>
          </div>
          <b-spinner small v-if="loading" class="mr-2"></b-spinner>
          <span v-if="loading">Uploading...</span>
        </div>
      </vue-dropzone>

      <div class="repeatable-images">
        <wdImageAvatarPreview
          v-for="(doc, index) in doc_urls"
          :key="index"
          :title="documentType"
          :coverImgUrl="doc"
          :fontAwClass="'fas fa-file-alt'"
          :isFileDelete="true"
          @delete-file="fnDocumentRemove(documentTypeId, index)"
        ></wdImageAvatarPreview>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "../../store/modules/base.module";
import * as authService from "../../services/auth.service";
import vueDropzone from "vue2-dropzone";
import * as messageService from "../../services/message.service";
import * as policeCheckService from "../../services/policeCheck.service";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";

export default {
  name: "TypeFrom9",
  props: [
    "policeCheckId",
    "form_no",
    "doc_urls",
    "documentTypeId",
    "documentType",
    "value",
  ],
  components: {
    vueDropzone,
    wdImageAvatarPreview,
  },
  data() {
    return {
      loading: false,
      optionsState: [
        { value: "NSW", text: "New South Wales" },
        { value: "QLD", text: "Queensland" },
        { value: "SA", text: "South Australia" },
        { value: "TAS", text: "Tasmania" },
        { value: "VIC", text: "Victoria" },
        { value: "WA", text: "Western Australia" },
        { value: "ACT", text: "Australian Capital Territory" },
        { value: "NT", text: "Northern Territory" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getPoliceCheckSingle"]),
    dropzoneOptions() {
      return {
        url: `${store.state.apiURL}/police-check/document-upload`,
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
        thumbnailWidth: 75,
        maxFilesize: 200,
        uploadMultiple: true,
        maxFiles: 10,
        paralleUploads: 10,
        previewsContainer: false,
        acceptedFiles: ".pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .png",
      };
    },
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle"]),
    // Dropzone document upload
    fnSendingDoc1(file, xhr, formData) {
      this.loading = true;

      formData.append("pId", this.policeCheckId);
      formData.append("documentTypeId", this.documentTypeId);
    },
    // Dropzone document upload after
    fnAfterSendingDoc1(file) {
      this.fetchPoliceCheckSingle(this.policeCheckId);
      this.loading = false;
    },
    fnDocumentRemove: async function (documentTypeId, index) {
      let obj = {
        documentTypeId: documentTypeId,
        index: index,
      };
      try {
        await policeCheckService.removeDoc(this.policeCheckId, obj);
        this.fetchPoliceCheckSingle(this.policeCheckId);

        this.error = {};

        messageService.fnToastSuccess("Document removed successfully!");
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Document removed",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Document removed",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Photo remove",
              "Something wrong, please try again!"
            );
            break;
        }
      }
    },
    updateValue(e) {
      this.$emit("input", {
        state: this.$refs.state.value,
        given_name: this.$refs.given_name.value,
        middle_name: this.$refs.middle_name.value,
        family_name: this.$refs.family_name.value,
        reference_no: this.$refs.reference_no.value,
        date_certificate_issued: this.$refs.date_certificate_issued.value,
      });
    },
  },
};
</script>
